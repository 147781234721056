<template>
  <div class="d-flex flex-column align-center pt-16">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    text() {
      switch (this.tab) {
        case 2:
          return "함께 프로젝트를 진행할 멤버를 초대해 주세요.";
        case 3:
          return "참여 신청한 멤버가 없습니다.";
        default:
          return "참여 중인 멤버가 없습니다.";
      }
    }
  }
};
</script>
