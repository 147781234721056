<template>
  <div class="cr-change-tab" @click="$emit('change:tab', tab.idx)">
    <span>{{ tab.text }}</span>
    <v-icon class="ml-1" size="18">mdi-chevron-right</v-icon>
  </div>
</template>

<style lang="scss" scoped>
.cr-change-tab {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: bold;
  color: #333;
  background: rgba(0, 0, 0, 0.03);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
}
</style>

<script>
export default {
  props: {
    tab: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
