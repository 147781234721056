<template>
  <div class="cr-project-member-list">
    <memberList
      v-for="tab in filteredTabs"
      :key="tab.value"
      v-bind="$props"
      v-on="$listeners"
    >
      <ChangeTab v-on="$listeners" :tab="tab" />
    </memberList>

    <slot v-if="_memberList.length" />
    <Empty v-if="showEmpty" v-bind="$props" />
    <Member
      v-for="member in _memberList"
      :key="member.userId"
      :member="member"
      v-bind="$props"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-project-member-list {
  &.cr-scroll {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden auto;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Empty from "./Empty.vue";
import Member from "./member";
import ChangeTab from "./ChangeTab.vue";

export default {
  name: "memberList",
  components: { Empty, Member, ChangeTab },
  props: {
    tab: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("flowProjects", ["managedMembers"]),
    filteredTabs() {
      const { key } = this.$vnode;
      if (key !== "ALL" || !this.managedMembers.length) return [];
      return this.tabs
        .map((t, idx) => ({ ...t, idx }))
        .filter(t => t.value !== "ALL")
        .reverse();
    },
    _memberList() {
      const { key } = this.$vnode;

      if (key === "ALL") return [];
      return this.managedMembers
        .filter(m => m.joinStatus === key)
        .map(m => ({ ...m }));
    },
    showEmpty() {
      // 전체 탭이고 프로젝트에 속한 멤버가 없을 때
      if (!this.tab && !this.managedMembers.length) return true;
      // 전체가 아니고 해당 탭에 멤버가 존재하지 않을 때
      if (this.tab && !this._memberList.length) return true;
      return false;
    }
  }
};
</script>
